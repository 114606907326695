import React, { useEffect } from "react";
import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { useAppContext } from "../../AppContext";

import u from "../../functions/utils";

/* eslint-disable react/prop-types */
export default function ChannelTypeSelector({ items }) {
    const { channelType, setChannelType, channels, channel } = useAppContext();

    const [selected, setSelected] = useState(u.isNonEmptyString(channel?.type) ? items.find((item) => item.id === channel.type) || items[0] : items[0]);

    useEffect(() => {
        if (u.isNonEmptyString(channel?.type)) {
            setSelected(items.find((item) => item.id === channel.type) || items[0]);
        }
    }, [channel]);

    useEffect(() => {
        let channelTypeToUpdate = channels.find((x) => x.type === selected.id);
        setChannelType(channelTypeToUpdate);
    }, [selected]);

    // Check if there are no items
    if (!items || items.length < 2) return null;

    return (
        <fieldset>
            <RadioGroup value={selected} onChange={setSelected} className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4">
                {items.map((item) => (
                    <Radio
                        key={item.id}
                        value={item}
                        aria-label={item.name}
                        className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-blue-300 bg-opacity-0 focus:bg-opacity-5 p-4 shadow-sm focus:outline-none data-[focus]:border-blu data-[focus]:ring-2 data-[focus]:ring-blu"
                    >
                        <span className="flex items-center">
                            <item.avatar className="h-5 w-5 text-blu_med" aria-hidden="true" />
                            <span className="flex flex-col">
                                <span className="block text-sm font-medium text-gray-900 ml-3 whitespace-normal">{item.name}</span>
                            </span>
                        </span>

                        <span
                            aria-hidden="true"
                            className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-blu group-data-[checked]:bg-opacity-10"
                        />
                    </Radio>
                ))}
            </RadioGroup>
        </fieldset>
    );
}
