import React from "react";
import { useAppContext } from "../../AppContext";
import u from "../../functions/utils";

export default function PayHeader() {
    let { amount } = useAppContext();

    return (
        <div className="rounded-lg py-2 text-center  lg:flex lg:flex-col lg:justify-center">
            <div className="mx-auto max-w-xs px-8">
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="font-bold tracking-tight text-gray-900 text-4xl sm:text-5xl">{u.displayCents(amount)}</span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">INR</span>
                </p>
                <p className="mt-2 text-xs leading-5 text-gray-600">Deposit Amount</p>
            </div>
        </div>
    );
}
