import React from "react";
import { useAppContext } from "../../AppContext";

import ChannelOptions from "./ChannelOptions";
import ChannelTypeSelector from "./ChannelTypeSelector";
import channelTypeItem from "./channel-type";

export default function ChannelSelector() {
    let { channels } = useAppContext();

    const channel_type_options = channels.map((c) => channelTypeItem(c));

    return (
        <form className="space-y-8" action="#" method="POST">
            <div>
                <div className="mt-2">
                    <ChannelTypeSelector items={channel_type_options} />
                </div>
                <ChannelOptions />
            </div>
        </form>
    );
}
