import React, { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./App.css";
import { useAppContext } from "./AppContext";

// components
import DoneComponent from "./components/DoneComponent";
import ErrorComponent from "./components/ErrorComponent";
import LoadingComponent from "./components/LoadingComponent";
import PayComponent from "./components/PayComponent";
import ToastsContainer from "./components/common/ToastContainer";

import loadPage from "./functions/load-page";

function App() {
    const { error, setError, loading, setRedirectUrl, setLoading, setChannels, setChannel, setAmount, page, setPage, setAppId, setToken, setChannelType } =
        useAppContext();

    const { appId } = useParams();
    const { search } = useLocation();

    const loadPageCalledRef = useRef(false);

    useEffect(() => {
        try {
            const params = new URLSearchParams(search);
            const redirect = params.get("redirect_url");
            const authToken = params.get("deposit");
            const isJwt = authToken && authToken.split(".").length === 3;

            if (!appId || !isJwt) throw Error("invalid url");

            setAppId(appId);
            setToken(authToken);
            setRedirectUrl(redirect);

            if (!loadPageCalledRef.current) {
                loadPage({ setPage, setChannelType, setError, setLoading, setChannels, setChannel, setAmount, app_id: appId, auth_token: authToken });
                loadPageCalledRef.current = true;
            }
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    }, [search, appId]);

    return (
        <div className="bg-white min-h-screen">
            {loading ? (
                <LoadingComponent />
            ) : error ? (
                <ErrorComponent />
            ) : page === "pay" ? (
                <PayComponent />
            ) : page === "done" ? (
                <DoneComponent />
            ) : (
                <ErrorComponent />
            )}
            <ToastsContainer />
        </div>
    );
}

export default App;
