import React from "react";
import { useAppContext } from "../AppContext";
import PayHeader from "./pay/PayHeader";
import SubmitComponent from "./pay/SubmitComponent";
import ChannelSelector from "./pay/ChannelSelector";

export default function PayComponent() {
    const { channel } = useAppContext();

    return (
        <div className="bg-white px-[4vw] py-[2.4vw] bg-transparent flex justify-center">
            <div className="flex flex-col bg-white rounded-[20px]  w-full max-w-[400px] min-h-[0px] p-2">
                <PayHeader />
                {channel && (channel.type === "upi" || channel.type === "bank_transfer") ? <SubmitComponent channel={channel} /> : <ChannelSelector />}
            </div>
        </div>
    );
}
